import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].ws_server;

class UserService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async login(username, password, recaptcha) {
    try {
      let response = await this.http.post(`${ server_base }/api/user/login`, { username, password, recaptcha });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }

  async companyUsers() {
    try {
      let response = await this.http.get(`${ server_base }/api/user`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async listByFinanceAccess(limit, skip, company) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( company ) query.company = company;

      this.addLoading('list_by_finance_access');
      const response = await this.http.get(`${ server_base }/api/user/access/finance-users`, query);
      this.delLoading('list_by_finance_access');
      return response.data;

    } catch (e) {
      this.delLoading('list_by_finance_access');
      return null;
    }
  }

  async toggleDND() {
    try {
      const response = await this.http.put(`${ server_base }/api/user/dnd`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async toggleIdle(isIdle) {
    try {
      const response = await this.http.put(`${ server_base }/api/user/idle`, { isIdle });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async setLanguage(language) {
    try {
      const response = await this.http.put(`${ server_base }/api/user/language`, { language });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  logout(id) {
    return this.http.put(`${ server_base }/api/user/logout`, { 'UserId': id });
  }

  async resetPassword(email, recaptcha, token, newPassword, newPasswordConfirm) {
    try {
      const response = await this.http.put(`${ server_base }/api/user/reset_password`, {
        email,
        recaptcha,
        token,
        newPassword,
        newPasswordConfirm
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async requestResetPassword(email, recaptcha) {
    try {
      const response = await this.http.post(`${ server_base }/api/user/reset_password`, { email, recaptcha });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async changePassword(email, recaptcha, currentPassword, newPassword, newPasswordConfirm) {
    try {
      let response = await this.http.put(`${ server_base }/api/user/change_password`, {
        email,
        recaptcha,
        currentPassword,
        newPassword,
        newPasswordConfirm
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async myAgents(limit, skip) {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/my_agents?limit=${ limit }&skip=${ skip }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async searchAgents(query) {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/search?query=${ query }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async directory() {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/directory`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async edit(user) {
    try {
      let response = await this.http.put(`${ server_base }/api/user`, { user });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async verifyFields(value) {
    try {
      let response = await this.http.get(`${ server_base }/api/user/verify?${ value }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async Impersonal(id, type = "in") {
    try {
      let response = await this.http.get(`${ server_base }/api/user/impersonate/${ id }?type=${ type }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async get(id) {
    try {
      let response = await this.http.get(`${ server_base }/api/user/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getLeadAccess(id) {
    try {
      const response = await this.http.get(`${ server_base }/api/user/${ id }/lead-access-status`);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default UserService;