import IService from './iservice';
import store from '@/store/store';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class CompanyService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async get(id) {
    try {
      let response = await this.http.get(`${ server_base }/api/company/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async edit(company) {
    try {
      const response = await this.http.put(`${ server_base }/api/company`, { company });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getSettings() {
    try {
      this.addLoading('company-settings');
      const response = await this.http.get(`${ server_base }/api/company/settings`);
      this.delLoading('company-settings');
      return response.data;
    } catch (e) {
      this.delLoading('company-settings');
      return null;
    }
  }

  async getTimeZones() {
    try {
      const response = await this.http.get(`${ server_base }/static/timezones.json`);
      return response.data;
    } catch (e) {
      return [];
    }
  }

  async getInventorySettings() {
    try {

      let response = await this.http.get(`${ server_base }/api/company/inventory_settings`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getForwardCRMSettings() {
    try {

      let response = await this.http.get(`${ server_base }/api/company/forward_crm_settings`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateForwardCRMSettings(record) {
    try {

      let response = await this.http.put(`${ server_base }/api/company/forward_crm_settings`, record);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters = {}) {
    try {
      const query = {};

      if ( filters.search ) query.search = filters.search;

      this.addLoading('list');
      let response = await this.http.get(`${ server_base }/api/company?limit=${ limit }&skip=${ skip }`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async allActive() {
    try {
      let response = await this.http.get(`${ server_base }/api/company/actives`);
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async editLogo(id, logo) {
    try {
      let response = await this.http.put(`${ server_base }/api/company/logo`, { id, logo });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getFeedConfig(type) {
    try {
      let response = await this.http.get(`${ server_base }/api/company/feed_config?type=${ type }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getFeedFiles() {
    try {
      this.addLoading('list');
      let response = await this.http.get(`${ server_base }/api/company/feed_files`);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async getFeedHistory(company = null, type, limit = '', skip = '') {
    try {
      this.addLoading('list');
      let response = await this.http.get(`${ server_base }/api/data-import?company=${ company }&type=${ type }&limit=${ limit }&skip=${ skip }`,);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async updateFeedConfig(columns, type) {
    try {
      let response = await this.http.put(`${ server_base }/api/company/feed_config`, { columns, type });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateScoreLevels(levels) {
    try {
      let response = await this.http.put(`${ server_base }/api/company/score_levels`, { levels });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateFtpFeedConfig(columns) {
    try {
      let response = await this.http.put(`${ server_base }/api/company/ftp_feed_config`, { columns });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async editInventory(id, inventory) {
    try {
      this.addLoading('edit');
      let response = await this.http.put(`${ server_base }/api/company/inventory`, { id, inventory });
      this.delLoading('edit');
      return response.data;
    } catch (e) {
      this.delLoading('edit');
      return null;
    }
  }

  async editWidget(id, type, widget) {
    try {
      this.addLoading('edit');
      const response = await this.http.put(`${ server_base }/api/company/${ type }_widget`, { id, widget });
      this.delLoading('edit');
      return response.data;
    } catch (e) {
      this.delLoading('edit');
      return null;
    }
  }

  async editLeadRules(companySettingsId, leadRules) {
    try {
      this.addLoading('edit');
      const response = await this.http.put(`${ server_base }/api/company/lead-rules`, { companySettingsId, leadRules });
      this.delLoading('edit');
      return response.data;
    } catch (e) {
      this.delLoading('edit');
      return null;
    }
  }


  async create(body) {
    try {

      let response = await this.http.post(`${ server_base }/api/company`, body);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async locations(limit = '', skip = '', company = '') {
    try {

      let response = await this.http.get(`${ server_base }/api/company/locations?limit=${ limit }&skip=${ skip }&company=${ company }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async widgetHash() {
    try {

      let response = await this.http.get(`${ server_base }/api/company/hash`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      let response = await this.http.delete(`${ server_base }/api/company/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async verifyFields(value) {
    try {
      let response = await this.http.get(`${ server_base }/api/company/verify?${ value }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async Impersonal(company) {
    try {
      let response = await this.http.get(`${ server_base }/api/company/impersonate/${ company }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async editPackagePlan(id, packagePlan) {
    try {
      const response = await this.http.put(`${ server_base }/api/company/package-plan`, { id, packagePlan });
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default CompanyService;