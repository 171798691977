import HttpClient from "../helpers/http.client";


class IService {
  constructor(http) {
    this.http = http;
    if ( !http )
      this.http = new HttpClient();
  }

  addLoading(value) {
    this.store.dispatch('addLoading', value);
  }

  delLoading(value) {
    this.store.dispatch('delLoading', value);
  }
}

export default IService;
